import React, { ReactElement } from 'react';
import { AiOutlineCar } from '@react-icons/all-files/ai/AiOutlineCar';
import { BiGame } from '@react-icons/all-files/bi/BiGame';
import { BiMovie } from '@react-icons/all-files/bi/BiMovie';
import { FiBookOpen } from '@react-icons/all-files/fi/FiBookOpen';

import styles from './interest-card.module.scss';

interface IInterestCard {
  icon: string;
  title: string;
}

const getIcon = (icon: string) => {
  switch (icon) {
    case 'book':
      return FiBookOpen;
    case 'car':
      return AiOutlineCar;
    case 'game':
      return BiGame;
    case 'movie':
      return BiMovie;
    default:
      return FiBookOpen;
  }
};

const InterestCard = ({ icon, title }: IInterestCard): ReactElement => {
  const Icon = getIcon(icon);
  return (
    <div className={styles.container}>
      <Icon className={styles.icon} />
      <p>{title}</p>
    </div>
  );
};

export default InterestCard;
