import React, { ReactElement, useRef } from 'react';
import { Image } from '@/components/image/image.component';
import { useAnimationHelper } from '@/utils/sections.helper';
import styles from './tech-card.module.scss';

interface iTechCard {
  src: string;
  title: string;
  position: number;
}

const getArray = () => {
  // TODO: Improve logic to generate array
  const array = [...Array(16).keys()];
  let i = 0;
  const SPLIT_LENGTH = matchMedia('(max-width: 768px)').matches ? 2 : 3;
  let shouldSkip = false;
  const newArr = array.filter(() => {
    if (i < SPLIT_LENGTH && !shouldSkip) {
      i++;
      return true;
    } else {
      i--;
      shouldSkip = i !== 0;
      return false;
    }
  });
  return newArr;
};

export const TechCard = (props: iTechCard): ReactElement => {
  const { src, title, position } = props;
  const cardRef = useRef<any>();
  const shouldAnimate = useAnimationHelper(cardRef, { rootMargin: '0% 0% -20% 0%' });
  const animateClass = shouldAnimate ? (getArray().includes(position) ? styles.animate_in_left : styles.animate_in_right) : '';
  return (
    <section ref={cardRef} className={`${styles.container} ${animateClass}`}>
      <Image src={src} className={styles.icon} />
      <span className={styles.title}>{title}</span>
    </section>
  );
};
