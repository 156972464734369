import React, { FormEvent, Fragment, KeyboardEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { EVENTS, GAservice } from '@/utils/analytics.helper';
import { SCROLL_TIME_DELAY, sleep } from '@/utils/general.helper';
import { ABOUT_SECTION_REF as aboutSectionRef, PROJECT_SECTION_REF as projectSectionRef } from '@/utils/sections.helper';
import { AVAILABLE_COMMANDS, downloadResume, getCurrentTime, IAvailableCommands } from '@/utils/terminal.helper';

import { CommandList, RandomFun, SuccessCommand, WrongCommand, TerminalSkills, AsyncCommand } from './components/commands.sub-component';
import { Facts } from './components/facts.sub-component';

import styles from './terminal.module.scss';

type ICommand = IAvailableCommands | Omit<string, IAvailableCommands>;

const Terminal = (): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [command, setCommand] = useState<ICommand>('');

  const handleCommandsTextClick = () => {
    if (inputRef.current) {
      setCommand('commands');
      inputRef.current.focus();
    }
  };

  const [terminalBody, setTerminalBody] = useState<ReactElement[]>([
    <Facts />,
    <p className={styles.hint_text}>
      {`Type "`}
      <button onClick={handleCommandsTextClick}>commands</button>
      {`" in the terminal window and hit enter to get list of all available commands`}
    </p>,
  ]);
  const [terminalHistory, setTerminalHistory] = useState<ICommand[]>([]);
  const terminalHistoryIndex = useRef<number>(terminalHistory.length);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    terminalHistoryIndex.current = terminalHistory.length;
  }, [terminalHistory]);

  const handleAvailableCommands = () => {
    let gaAction = 'clear';
    if (command === 'clear') {
      setTerminalBody([]);
    } else if (command === 'commands') {
      gaAction = 'get list';
      setTerminalBody(prevBody => [...prevBody, <SuccessCommand title={command} />, <CommandList />]);
    } else if (command === 'code' || command === 'kill') {
      gaAction = 'show meme';
      setTerminalBody(prevBody => [...prevBody, <SuccessCommand title={command} />, <RandomFun command={command as 'code' | 'kill'} />]);
    } else if (command === 'skills') {
      gaAction = 'show meme';
      setTerminalBody(prevBody => [...prevBody, <SuccessCommand title={command} />, <TerminalSkills />]);
    } else if (command === 'facts') {
      gaAction = 'display info';
      setTerminalBody(prevBody => [...prevBody, <SuccessCommand title={command} />, <Facts />]);
    } else if (command === 'about') {
      gaAction = 'scroll to section';
      setTerminalBody(prevBody => [...prevBody, <SuccessCommand title={command} />, <AsyncCommand title={'Finding about information'} />]);
      sleep(SCROLL_TIME_DELAY).then(() => aboutSectionRef?.current?.scrollIntoView?.());
    } else if (command === 'projects') {
      gaAction = 'scroll to section';
      setTerminalBody(prevBody => [
        ...prevBody,
        <SuccessCommand title={command} />,
        <AsyncCommand title={'Organizing all the projects'} />,
      ]);
      sleep(SCROLL_TIME_DELAY).then(() => projectSectionRef?.current?.scrollIntoView?.());
    } else if (command === 'resume') {
      setTerminalBody(prevBody => [...prevBody, <SuccessCommand title={command} />, <AsyncCommand title={'Downloading Resume'} />]);
      gaAction = 'download resume';
      sleep(SCROLL_TIME_DELAY).then(() => downloadResume());
    }
    GAservice.publish({ ...EVENTS.terminal_command, action: gaAction, label: command as string });
  };

  const handleCommandPress = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (AVAILABLE_COMMANDS.includes(command as IAvailableCommands)) {
      handleAvailableCommands();
    } else {
      setTerminalBody(prevBody => [...prevBody, <WrongCommand title={command} />]);
      GAservice.publish({ ...EVENTS.terminal_command, action: 'invalid command', label: command as string });
    }
    setTerminalHistory(prevHistory => [...prevHistory, command]);
    setCommand('');
  };

  const handleUpKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'ArrowUp' && terminalHistory.length) {
      if (terminalHistoryIndex.current > 0) {
        setCommand(terminalHistory[terminalHistoryIndex.current - 1]);
        terminalHistoryIndex.current -= 1;
      }
    } else if (event.key === 'ArrowDown' && terminalHistoryIndex.current !== terminalHistory.length) {
      if (terminalHistoryIndex.current < terminalHistory.length - 1) {
        setCommand(terminalHistory[terminalHistoryIndex.current + 1]);
        terminalHistoryIndex.current += 1;
      }
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.headers}>
        <div className={styles.buttons}>
          <span className={styles.close_button}>
            <span />
          </span>
          <span className={styles.minimize_button} />
          <span className={styles.maximize_button} />
        </div>
        <p className={styles.title}>{`psp@developer : ~/root`}</p>
      </div>
      <div className={styles.body}>
        <div className={styles.contents}>
          <div className={styles.contents_inner}>
            {terminalBody.map((body, index) => (
              <Fragment key={index}>{body}</Fragment>
            ))}
          </div>
        </div>
        <br />
        <form className={styles.input_container} onSubmit={handleCommandPress}>
          <label id={'label'} className={styles.term_icon}>
            ❯
          </label>
          <input
            aria-labelledby="label"
            ref={inputRef}
            className={styles.input}
            value={command as string}
            onChange={({ target }) => setCommand(target.value)}
            onKeyDown={handleUpKeyPress}
          />
        </form>
      </div>
      <div className={styles.footer}>
        <span className={styles.terminal_size}>{'80 x 24'}</span>
        <span className={styles.owner_info}>{`Parth's Laptop`}</span>
        <span className={styles.time}>{getCurrentTime()}</span>
      </div>
    </div>
  );
};

export default Terminal;
