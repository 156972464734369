import React, { ReactChild, ReactElement, useEffect } from 'react';
import { THEME_CONTEXT, useThemeHelper } from '@/utils/theme.helper';

interface ILayout {
  children: ReactChild | ReactChild[];
}

const Layout = (props: ILayout): ReactElement => {
  const { children } = props;
  const themeHelper = useThemeHelper();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <THEME_CONTEXT.Provider value={themeHelper}>{children}</THEME_CONTEXT.Provider>;
};

export default Layout;
