import React, { forwardRef, Fragment, ReactElement, useState } from 'react';
import { FaCode } from '@react-icons/all-files/fa/FaCode';
import { FaPlay } from '@react-icons/all-files/fa/FaPlay';
import { Image } from '@/components';
import { EVENTS, GAservice } from '@/utils/analytics.helper';
import { IProjectCard, ProjectStatus, STATUS_LIST } from '@/utils/projects.helper';
import styles from './project-card.module.scss';

const DescriptionText = ({ text = '' }: { text?: string }) => {
  const [showLess, setShowLess] = useState(true);
  const length = 120;
  if (text.length < length) {
    return <p>{text}</p>;
  }
  return (
    <Fragment>
      <p className={showLess ? styles.show_less : styles.show_more}>{text}</p>
      <button onClick={() => setShowLess(!showLess)}>Read {showLess ? 'More' : 'Less'}</button>
    </Fragment>
  );
};

const ProjectCard = forwardRef((projectInfo: IProjectCard, ref: any): ReactElement => {
  const [isActive, setActive] = useState(false);
  const shouldShowHeader = Object.values(projectInfo?.links || {}).length > 0 && projectInfo.status;
  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.header}>
        {projectInfo.coverImage && <Image src={projectInfo.coverImage} className={styles.project_cover} />}
        {shouldShowHeader && (
          <div
            className={`${styles.project_info} ${isActive ? styles.project_info_active : ''}`}
            onMouseLeave={() => {
              if (isActive) {
                setActive(false);
              }
            }}
          >
            <span className={styles.project_status} style={{ color: STATUS_LIST[(projectInfo.status || 'active') as ProjectStatus] }}>
              {projectInfo.status}
            </span>
            {projectInfo.links?.src && (
              <a
                href={projectInfo.links.src}
                target={'_blank'}
                className={styles.source_button}
                onClick={() =>
                  GAservice.publish({ ...EVENTS.project_actions, action: 'visited project info', label: `source of ${projectInfo.title}` })
                }
              >
                <FaCode className={styles.icon} />
                <span>Source</span>
              </a>
            )}

            {projectInfo.links?.demo && (
              <a
                href={projectInfo.links.demo}
                target={'_blank'}
                className={styles.source_button}
                onClick={() =>
                  GAservice.publish({ ...EVENTS.project_actions, action: 'visited project info', label: `demo of ${projectInfo.title}` })
                }
              >
                <FaPlay className={styles.icon} />
                <span>demo</span>
              </a>
            )}

            <button
              aria-label="Toggle project info"
              className={styles.expand_button}
              onMouseOver={() => {
                if (!isActive) {
                  setActive(true);
                }
              }}
            >
              <span className={styles.expand_arrow}>
                <span />
                <span />
              </span>
            </button>
          </div>
        )}

        <div className={styles.image_wrapper}>
          <Image src={projectInfo.logoImage} className={styles.project_logo} />
        </div>
      </div>
      <div className={styles.body}>
        <span>{projectInfo.title}</span>
        <DescriptionText text={projectInfo.description} />
        {/* TODO: Add feature of tags related to the project */}
        {/* {projectInfo.tags?.length ? (
          <div className={styles.tags}>
            {projectInfo.tags?.map(tag => (
              <span key={tag} style={{ backgroundColor: COLORS_LIST[8] }}>
                {tag}
              </span>
            ))}
          </div>
        ) : (
          <></>
        )} */}
      </div>
    </div>
  );
});

export default ProjectCard;
