import React, { ReactElement } from 'react';
import { Image, Title } from '@/components';
import { EVENTS, GAservice } from '@/utils/analytics.helper';
import { ABOUT_SECTION_REF as aboutSectionRef, SECTION_IDs, useAnimationHelper } from '@/utils/sections.helper';
import InterestCard from './interest-card/interest-card.component';
import SkillCard from './skill-card/skill-card.component';
import styles from './about.module.scss';

const About = (): ReactElement => {
  const shouldAnimateSections = useAnimationHelper(aboutSectionRef, { rootMargin: '0% 0% -80% 0%' });
  return (
    <section id={SECTION_IDs.about} ref={aboutSectionRef} className={styles.container}>
      <div className={styles.wrapper}>
        <Title title="About" />
        <div className={styles.highlights_container}>
          <SkillCard
            iconName={'engineer'}
            title={'Software Engineer'}
            description={'An engineer by a chance, a programmer by the choice'}
          />
          <SkillCard
            iconName={'tech-enthusiast'}
            title={'Passionate Coder'}
            description={'Passionate about bleeding edge technologies and their what-about!'}
            animationDelay={'0.2s'}
          />
          <SkillCard
            iconName={'game'}
            title={'Hardcore Gamer'}
            description={'Game enthusiast, who likes to play RPG and survival games in free time'}
            animationDelay={'0.4s'}
          />
          <SkillCard
            iconName={'path'}
            title={'Alternate Believer'}
            description={`I always believe there are multiple ways to solve a single problem`}
            animationDelay={'0.6s'}
          />
        </div>
        <div className={styles.about_container}>
          <div className={`${styles.left_section} ${shouldAnimateSections ? styles.left_section_animate_in : ''}`}>
            <div className={styles.image_container}>
              <div className={styles.image_wrapper}>
                <Image src={'avatar.jpg'} />
              </div>
            </div>
            <h3>Who's this guy ?</h3>
            <p>
              {`I'm MERN Stack Engineer with 4 years of development experience`}
              <br /> {`just a living organism that turns your ideas into products`}
            </p>
          </div>
          <div className={`${styles.right_section} ${shouldAnimateSections ? styles.right_section_animate_in : ''}`}>
            <h3>what's his interests are like ?</h3>
            <div className={styles.interest_container}>
              <InterestCard icon={'book'} title={'Reading Novel'} />
              <InterestCard icon={'car'} title={'Long Drives'} />
              <InterestCard icon={'movie'} title={'Movies'} />
              <InterestCard icon={'game'} title={'Gaming'} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
