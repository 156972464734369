import React, { FormEvent, ReactElement } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { Input } from '@/components';
import { EVENTS, GAservice } from '@/utils/analytics.helper';
import { useFormHelper } from '@/utils/contacts.helper';
import styles from './input-form.module.scss';

const InputForm = (): ReactElement => {
  const { formData, formErrors, isSubmitting, handleInput, validateForm, submitForm } = useFormHelper();

  const handleFormSubmission = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateForm()) {
      GAservice.publish({ ...EVENTS.call_to_action, label: 'submitted contact form' });
      toast.promise(submitForm(), {
        loading: 'Submitting...',
        success: <b>Message Sent!</b>,
        error: <b>Unable to send message.</b>,
      });
    }
  };
  return (
    <div className={styles.right_section}>
      <Toaster position="bottom-center" />
      <form onSubmit={handleFormSubmission}>
        <div className={styles.input_horizontal_wrapper}>
          <Input
            value={formData.name}
            label="Name"
            name="name"
            placeholder="Enter your name here"
            errorMessage={formErrors.name}
            onChange={handleInput}
          />
          <Input
            value={formData.email}
            label="Email"
            name="email"
            placeholder="Enter your email address here"
            errorMessage={formErrors.email}
            onChange={handleInput}
          />
        </div>
        <Input
          label="Subject"
          value={formData.subject}
          name="subject"
          placeholder="Reason why you are interested in a talk"
          errorMessage={formErrors.subject}
          onChange={handleInput}
        />
        <Input.Textarea
          label="Message"
          value={formData.message}
          name="message"
          placeholder="Enter message here ..."
          errorMessage={formErrors.message}
          onChange={handleInput}
        />
        <div className={styles.button_wrapper}>
          <button type="submit" disabled={isSubmitting}>
            <div className={styles.progress_indicator} style={isSubmitting ? {} : { display: 'none' }} />
            Send message
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputForm;
