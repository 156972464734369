import React, { ReactElement } from 'react';

import GitIcon from '@/assets/svg/github.svg';
import InstagramIcon from '@/assets/svg/instagram.svg';
import LinedInIcon from '@/assets/svg/linkedin.svg';
import PinterestIcon from '@/assets/svg/pinterest.svg';
import TwitterIcon from '@/assets/svg/twitter.svg';
import { GAservice, EVENTS } from '@/utils/analytics.helper';
import { SOCIAL_URLS } from '@/utils/contacts.helper';

import styles from './social-media.module.scss';

const SocialMediaSection = (): ReactElement => {
  const publishEventToGA = (label: string) => {
    const event = { ...EVENTS.social_media, label };
    GAservice.publish(event);
  };

  return (
    <div className={styles.left_section}>
      <section className={styles.random_circle} />

      <div>
        <p className={styles.contact_title}>Contact Information</p>
        <p className={styles.contact_sub_title}>Fill up the form and I will get back to you within 24 hours</p>
      </div>

      <div className={styles.social_media_container}>
        <a aria-label="Find me on Github" target={'_blank'} href={SOCIAL_URLS.github} onClick={() => publishEventToGA('Github')}>
          <GitIcon />
        </a>
        <a aria-label="Find me on LinkedIn" target={'_blank'} href={SOCIAL_URLS.linkedIn} onClick={() => publishEventToGA('LinkedIn')}>
          <LinedInIcon />
        </a>
        <a aria-label="Find me on Twitter" target={'_blank'} href={SOCIAL_URLS.twitter} onClick={() => publishEventToGA('Twitter')}>
          <TwitterIcon />
        </a>
        <a aria-label="Find me on Pinterest" target={'_blank'} href={SOCIAL_URLS.pinterest} onClick={() => publishEventToGA('Pinterest')}>
          <PinterestIcon />
        </a>
        <a aria-label="Find me on Instagram" target={'_blank'} href={SOCIAL_URLS.instagram} onClick={() => publishEventToGA('Instagram')}>
          <InstagramIcon />
        </a>
      </div>
    </div>
  );
};

export default SocialMediaSection;
