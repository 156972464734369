import React, { ReactElement } from 'react';
import { Title } from '@/components';
import TechStack from '@/constants/tech-stack.json';
import { SECTION_IDs, TECH_STACK_SECTION_REF } from '@/utils/sections.helper';
import { TechCard } from './tech-card/tech-card.component';
import styles from './tech-stack.module.scss';

const Skills = (): ReactElement => {
  return (
    <section id={SECTION_IDs['tech-stack']} className={styles.container} ref={TECH_STACK_SECTION_REF}>
      <div className={styles.wrapper}>
        <Title title={'Tech Stack'} reverse />
        <div className={styles.tech_container}>
          {TechStack.map((stack, index) => (
            <TechCard src={stack.image} title={stack.title} key={stack.title} position={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
