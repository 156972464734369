import React, { ReactElement, useRef } from 'react';
import { useAnimationHelper } from '@/utils/sections.helper';
import styles from './timeline-card.module.scss';
interface ITimelineCard {
  title: string;
  description: string;
  period?: string;
}

export const TimelineCard = (props: ITimelineCard): ReactElement => {
  const { title, description, period } = props;
  const cardRef = useRef<any>();
  const shouldAnimate = useAnimationHelper(cardRef, { rootMargin: '0% 0% -20% 0%' });
  return (
    <div ref={cardRef} className={`${styles.container} ${shouldAnimate ? styles.animate_in : ''}`}>
      <section className={styles.info_section}>
        <p className={styles.title}>{title}</p>
        <p className={styles.description}>{description}</p>
      </section>
      <section className={styles.timeline_maker}>
        <span className={styles.icon} />
        <span className={styles.line} />
      </section>
      <span className={styles.time_period_container}>
        <span className={styles.time_period}>{period}</span>
      </span>
    </div>
  );
};
