import React, { ReactElement, useContext } from 'react';
import { HOME_SECTION_REF, SECTION_IDs } from '@/utils/sections.helper';
import { THEME_CONTEXT } from '@/utils/theme.helper';

import Phone from './phone/phone.component';
import Terminal from './terminal/terminal.component';
import styles from './home.module.scss';

const Home = (): ReactElement => {
  const { isDarkTheme } = useContext(THEME_CONTEXT);
  return (
    <section ref={HOME_SECTION_REF} id={SECTION_IDs.home} className={isDarkTheme ? styles.container_dark : styles.container}>
      <Phone />
      <Terminal />
    </section>
  );
};

export default Home;
