import React, { ReactElement, useRef } from 'react';
import { Title } from '@/components';
import { JOURNEY_SECTION_REF, SECTION_IDs, useAnimationHelper } from '@/utils/sections.helper';
import { TimelineCard } from './timeline-card/timeline-card.component';
import styles from './journey.module.scss';

const Journey = (): ReactElement => {
  const graduationRef = useRef<any>();
  const shouldAnimate = useAnimationHelper(graduationRef, { rootMargin: '0% 0% -20% 0%' });
  return (
    <section id={SECTION_IDs.journey} className={styles.container} ref={JOURNEY_SECTION_REF}>
      <div className={styles.wrapper}>
        <Title title={'The Journey'} />
        <div className={styles.journey_container}>
          <TimelineCard
            title={'First encounter'}
            description={`It all started when I was in 6th std. I got introduced to world of tech with brand new pc at home. I used to play games most of the times. But later, I started mesmerizing their functionalities and usability.`}
            period={`2007`}
          />
          <TimelineCard
            title={'The High School'}
            description={`At this point, I started to make use of the internet in all possible ways, surfing became a hobby and getting to know something was cool. 
            I had my first android phone during this period. I'd spend my days trying experimenting and learning modes related to mobile development`}
            period={`2013-2015`}
          />
          <TimelineCard
            title={'Quadrennial period'}
            description={`As I was already fascinated by gadgets and tech, I decided to pursue my career in Computer Engineering. Over a period of time I got more interested mobile development as, on that's what we used to spend our most of time on. 
            So in the 3rd year of college, I started developing android apps for fun :)`}
            period={`2015-2019`}
          />
          <div ref={graduationRef} className={`${styles.graduation_container} ${shouldAnimate ? styles.animate_in : ''}`}>
            <p className={styles.graduation_title}>10th June, 2019</p>
            <p className={styles.graduation_info}>
              After Graduation, I had relevant experience in field, but not enough to survive in the current trends. Fortunately, got an
              opportunity from a company called Coditas. I decided to join and it became my first job in professional career.
            </p>
          </div>
          <div />
          <TimelineCard
            title={'Associate Software Engineer'}
            description={`Initially, I was more focused towards working on specific framework but luckily I had good mentors, who taught me not to limit myself ever on a framework as tech is really a vast field.
            over a period of time it changed and things were falling into place. I underwent training for 3 months in JS based frameworks.`}
            period={`2019`}
          />
          <TimelineCard
            title={'Software Engineer'}
            description={`I've been part of end-to-end development cycle. I've worked as frontend engineer on multiple products and delivered them successfully`}
            period={`2019-2022`}
          />
          <TimelineCard
            title={'Senior Software Engineer'}
            description={`One thing that I've learned from my journey till now, is how to write maintainable, scalable, optimized code. I believe good programmers write code that human understands `}
            period={`2022-Apr 2023`}
          />
        </div>
      </div>
    </section>
  );
};

export default Journey;
