import React, { Fragment, ReactElement } from 'react';

import styles from './facts.module.scss';

export const Facts = (): ReactElement => {
  return (
    <Fragment>
      <p className={styles.intro_title}>Hii ! I'm Parth 💻</p>
      <p className={styles.fact_title}>{`Here's some facts about me:`}</p>
      <ul className={styles.fact_list_container}>
        <li>Mobile Developer</li>
        <li>Web Developer</li>
        <li>Tech Enthusiast</li>
        <li>Passionate Gamer</li>
        <li>Gadgets Lover</li>
      </ul>
    </Fragment>
  );
};
