import { ChangeEvent, useState } from 'react';

export const SOCIAL_URLS = {
  linkedIn: 'https://linkedin.com/in/pspatel1068',
  instagram: 'https://instagram.com/pspatel_',
  twitter: 'https://twitter.com/P_S_Patel',
  pinterest: 'https://pinterest.com/psp05',
  github: 'https://github.com/PSPatel5',
  email: 'mailto: pspatel1068@gmail.com',
  location: 'https://goo.gl/maps/F1Nf45gShLCngccV8',
};

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const DEFAULT_VALUES = {
  name: '',
  email: '',
  subject: '',
  message: '',
} as const;
interface iFormHelper {
  formData: typeof DEFAULT_VALUES;
  formErrors: { name?: string; email?: string; subject?: string; message?: string };
  isSubmitting: boolean;
  handleInput: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  validateForm: () => boolean;
  resetForm: () => void;
  submitForm: () => Promise<void>;
}

const FORM_SPREE_URL = 'https://formspree.io/p/1662217031748943261/f/contact';

export const useFormHelper = (): iFormHelper => {
  const [data, setFormData] = useState(DEFAULT_VALUES);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isValid = ({ key, value, regex }: { key: string; value: string; regex?: RegExp }) => {
    if (!value) {
      setErrors({ ...errors, [key]: `${key} is required` });
      return false;
    } else if (regex && !value.match(regex)) {
      setErrors({ ...errors, [key]: `error validating ${key}` });
      return false;
    } else {
      setErrors({ ...errors, [key]: '' });
      return true;
    }
  };

  const validateInput = (key: string, value: string) => {
    switch (key) {
      case 'name':
        return isValid({ key, value });
      case 'email':
        return isValid({ key, value, regex: emailRegex });
      case 'subject':
      case 'message':
        return isValid({ key, value });
      default:
        break;
    }
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;
    validateInput(name, value);
    setFormData({ ...data, [name]: value });
  };

  const resetForm = () => {
    setFormData(DEFAULT_VALUES);
    setErrors(DEFAULT_VALUES);
  };

  const validateForm = () => {
    const errorsObject = Object.entries(data).reduce((acc, [key, value]) => {
      const errorString = !value ? `${key} is required` : '';
      return errorString ? { ...acc, [key]: errorString } : acc;
    }, {});
    setErrors({ ...errors, ...errorsObject });
    return Object.entries(errorsObject).length < 1;
  };

  const submitForm = async (): Promise<void> => {
    const formData = new FormData();
    Object.entries(data).forEach((val: any) => formData.set(val[0], val[1]));
    try {
      setIsSubmitting(true);
      await fetch(FORM_SPREE_URL || '', {
        method: 'post',
        body: formData,
        mode: 'no-cors',
      });
      resetForm();
      return;
    } catch (error) {
      return;
    } finally {
      setIsSubmitting(false);
    }
  };

  return { formData: data, formErrors: errors, isSubmitting, handleInput, validateForm, resetForm, submitForm };
};
