export type ProjectStatus = 'in plan' | 'on hold' | 'active' | 'completed' | 'ceased';

export interface IProjectCard {
  title: string;
  description?: string;
  links?: {
    src?: string;
    demo?: string;
  };
  logoImage: string;
  coverImage?: string;
  tags?: string[];
  status?: ProjectStatus;
  type: 'personal' | 'professional';
}

export const STATUS_LIST = { 'in plan': 'white', active: '#00ff00', completed: '#FFFF00', 'on hold': '#FFFF00', ceased: 'red' };

const personalProjects: IProjectCard[] = [
  {
    title: 'RN-App-Intro',
    type: 'personal',
    description: `A React-Native intro library to beautify user onboarding experience. Completely written in typescript and fully customizable. This project is also available as an NPM package. It is an open source project with goal to give back community.`,
    links: {
      src: 'https://github.com/Parth-coditas/react-native-app-intro',
    },
    logoImage: 'react.png',
    coverImage: 'rn-app-intro-cover.jpg',
    status: 'completed',
    tags: ['Typescript', 'OSS', 'React Native', 'Onboarding', 'Cross Platform'],
  },
  {
    title: 'RN-Boilerplate',
    description: `A React-Native boilerplate made from scratch to kick start mobile application with widely used libraries and cleaner architecture. This project is also available as an NPM package.It is an open source project with goal to support clean code culture.`,
    links: {
      src: 'https://github.com/Parth-coditas/react-native-boilerplate',
    },
    logoImage: 'react.png',
    coverImage: 'rn-boilerplate-cover.jpg',
    status: 'active',
    type: 'personal',
    tags: ['React Native', 'Typescript', 'clean code', 'OSS', 'DX'],
  },
  {
    title: 'On Boarding Android library',
    description: `A pure java based library for android devices. It is an onboarding library by which developer add onboarding pages with default templates.Developers can customize fragments.Also , Developers can ask for permissions via these onboarding forms`,
    links: {
      src: 'https://github.com/PSP-Developers/Material-Onboard',
      demo: '',
    },
    logoImage: 'library.png',
    coverImage: 'android-intro-cover.jpg',
    status: 'completed',
    type: 'personal',
    tags: ['Android', 'App Intro', 'OSS', 'Java', 'Github'],
  },
  {
    title: 'BakeMyFood',
    description: `It was a hybrid application made via react native. It shows near by restaurants , available food items in that restaurants and let user order those items in multiple quantity.It was a demo project,It is not connected with any server`,
    links: {
      src: '',
      demo: 'https://play.google.com/store/apps/details?id=com.bakemyfood',
    },
    logoImage: 'bake-my-food.png',
    coverImage: 'bake-my-food-cover.jpg',
    status: 'completed',
    type: 'personal',
    tags: ['React Native', 'Javascript', 'Demo', 'Android', 'Local'],
  },
  {
    title: 'HeliosFit-X',
    description: `It was a pure android application, which had 2 different logins,doctor and patient.Via this application doctor can manage connected patient's workout plan/diet plan. Patient can request for an appointment to any doctor.`,
    // links: {
    //   src: '',
    //   demo: '',
    // },
    logoImage: 'helios-fit-x.png',
    coverImage: 'helios-fit-x-cover.jpg',
    status: 'ceased',
    type: 'personal',
    tags: ['Android', 'Mobile', 'Local', 'Gym', 'Health'],
  },
  {
    title: 'Dimized',
    description: `It was a pure android application.Main aim of this project was to track individual's income/expense on weekly/monthly bases.This app had features like automatically add income/expense on user specified intervals.eg. Salary (30 Days Income), EMI (30 Days expense). Due to server issues, This application is currently not in demo stage. But you can view screenshots on the store.`,
    // links: {
    //   src: '',
    //   demo: 'https://play.google.com/store/apps/details?id=com.pspdevelopers.dimized',
    // },
    logoImage: 'dimized.png',
    coverImage: 'dimized-cover.jpg',
    status: 'ceased',
    type: 'personal',
    tags: ['Android', 'Finance', 'Savings', 'Money'],
  },
  {
    title: 'BookMyHall',
    description: `It was a pure android application with 2 different mods, vendor and buyer.Hall vendors can manage their profile, add pictures , update availability of hall. Buyer can search for halls via location , availability and ratings.They can request vendor to book hall for multiple days. Due to server issues, This application is currently not in demo stage. But you can view screenshots on the store. `,
    // links: {
    //   src: '',
    //   demo: 'https://play.google.com/store/apps/details?id=com.psp.techtainer.bookmyhall',
    // },
    status: 'ceased',
    logoImage: 'book-my-hall.png',
    coverImage: 'book-my-hall-cover.jpg',
    type: 'personal',
    tags: ['Android', 'Product', 'Service Booking', 'Payment'],
  },
];

const professionalProjects: IProjectCard[] = [
  {
    title: 'CRM',
    description: `Company's internal app for managing projects and human resources. I worked on it during initial days of my career.During this I get to know basics of navigation, reusable components, styling and boilerplate.`,
    logoImage: 'crm.png',
    type: 'professional',
    status: 'ceased',
  },
  {
    title: 'Bandhan',
    description: `A React native Project. During the initial stage of this project I worked on React-native as well as React JS and Node JS. We faced data integrity issue so I also get to know about database migration on Mongo DB. After a while I re-wrote whole mobile application with optimized code, improved UI.`,
    // links: {
    //     demo: 'https://play.google.com/store/apps/details?id=com.bandhan',
    // },
    logoImage: 'bandhan.png',
    coverImage: 'bandhan-cover.jpg',
    status: 'completed',
    type: 'professional',
  },
  {
    title: 'MHID',
    description: `This Project had multiple products.Some of them were in React JS and some were in React Native, I worked on both. I learn multiple things like chat using firestore, OCR from driving licence, Managing flavours of application, Implementing In-app updates, Handling Push notifications etc. I also worked on React JS where I implemented responsive tables,session management.`,
    // links: {
    //     demo: 'https://play.google.com/store/apps/details?id=com.pverify.myhealthid',
    // },
    logoImage: 'my-health-id.png',
    coverImage: 'my-health-id-cover.jpg',
    status: 'completed',
    type: 'professional',
  },
  {
    title: 'H2H',
    description: `Another React Native Project. I get to learn and work on animating views, Handling multi-steps forms and managing state of application even when it's killed.This was a short term project but got to learn many things during this.`,
    logoImage: 'h2-health.png',
    coverImage: 'h2-health-cover.jpg',
    status: 'completed',
    type: 'professional',
  },
  {
    title: 'CAS',
    description: `A React-native application entirely made from scratch. During this project I learned things like managing application theme, Playing audio files from URL, Integrating Bug-tracker library, Managing different flavors etc.`,
    logoImage: 'cas.png',
    coverImage: 'cas-cover.jpg',
    status: 'on hold',
    type: 'professional',
  },
  {
    title: 'MKID',
    description: `Another React-native application I worked on. During this project I learned things like added quick replies to chat screen, Effectively handling chat messages and custom UIs, Minor UI animations, Integrating Stripe payment gateway, Creating Pixel-Perfect UI. Got chance to work on very old version of React Native.`,
    // links: {
    //     demo: 'https://play.google.com/store/apps/details?id=com.markid',
    // },
    status: 'completed',
    logoImage: 'mark-id.png',
    coverImage: 'mark-id-cover.jpg',
    type: 'professional',
  },
  {
    title: 'GLatka',
    description: `A Next.js based project, which shows data related to SaaS companies, their revenue, funding , customers and employee's strength.During this project I mostly worked on the front-end part, rewriting the whole application with cleaner architecture to make code-base more maintainable. Made responsive UI using various media queries and improved overall lighthouse reports via various fixes`,
    // links: {
    //     demo: 'https://getlatka.com',
    // },
    status: 'completed',
    logoImage: 'crm.png',
    coverImage: 'get-latka-cover.jpg',
    type: 'professional',
  },
  {
    title: 'Bumped',
    description: `A react-native mobile application which targets air-industry domain and provides different services such as flight rebooking, their status and about`,
    status: 'completed',
    logoImage: 'crm.png',
    type: 'professional',
  },
];

export const PROJECTS_LIST: IProjectCard[] = [...personalProjects, ...professionalProjects].sort((a, b) => (a.title > b.title ? 1 : -1));
