import React, { ReactElement } from 'react';
import Avatar from '@/assets/svg/avatar.svg';
import { EVENTS, GAservice } from '@/utils/analytics.helper';
import { CONTACT_SECTION_REF } from '@/utils/sections.helper';
import styles from './phone.module.scss';

const Phone = (): ReactElement => {
  return (
    <div className={styles.phone}>
      <div className={styles.phone_inner}>
        <div className={styles.phone_content}>
          <h1 className={styles.phone_title}>Hii There, Stranger !</h1>
          <Avatar className={styles.avatar} />
          <button
            className={styles.button}
            onClick={() => {
              GAservice.publish({ ...EVENTS.call_to_action, label: `Let's Talk` });
              CONTACT_SECTION_REF.current?.scrollIntoView?.();
            }}
          >
            Let's talk
          </button>
        </div>
      </div>
    </div>
  );
};

export default Phone;
