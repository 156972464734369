import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { SCROLL_TIME_DELAY, sleep } from '@/utils/general.helper';
import { TECH_STACK_SECTION_REF } from '@/utils/sections.helper';
import { AVAILABLE_COMMANDS, AVAILABLE_FUN, IAvailableCommands } from '@/utils/terminal.helper';

import styles from './commands.module.scss';

interface IRandomFun {
  command: 'code' | 'kill' | 'skills';
}

interface ICommand {
  title: IAvailableCommands | Omit<string, IAvailableCommands>;
}

interface IAsyncCommand {
  title: string;
}

export const RandomFun = ({ command }: IRandomFun): ReactElement => {
  return (
    <Fragment>
      <p className={styles.random_fun_title}>{AVAILABLE_FUN[command].title}</p>
      <div>
        <img className={styles.random_fun_image} src={AVAILABLE_FUN[command].image} />
      </div>
    </Fragment>
  );
};

export const TerminalSkills = (): ReactElement => {
  return (
    <Fragment>
      <RandomFun command="skills" />
      <p className={styles.normal_text}>
        {`Haha! Just kidding. `}
        <button onClick={() => TECH_STACK_SECTION_REF.current?.scrollIntoView?.()}>Click Here</button>
        {` to get details of all my skills`}
      </p>
    </Fragment>
  );
};

export const WrongCommand = (props: ICommand): ReactElement => {
  const { title } = props;
  return <p className={styles.wrong_command}>{`command: "${title}" not found`}</p>;
};

export const SuccessCommand = (props: ICommand): ReactElement => {
  const { title } = props;
  return <p className={styles.success_command}>{`${title}`}</p>;
};

export const AsyncCommand = (props: IAsyncCommand): ReactElement | null => {
  const { title } = props;
  const [isPromiseResolved, setIsPromiseResolved] = useState<boolean>(false);
  useEffect(() => {
    sleep(SCROLL_TIME_DELAY).then(() => {
      setIsPromiseResolved(true);
    });
  }, []);
  return <p className={`${styles.info_text} ${isPromiseResolved ? styles.not_after : ''}`}>{title}</p>;
};

export const CommandList = (): ReactElement => (
  <Fragment>
    <p className={styles.normal_command}>Commands available:</p>
    {AVAILABLE_COMMANDS.map((command, index) => (
      <p className={styles.normal_command} key={index}>
        {command}
      </p>
    ))}
  </Fragment>
);
