import React, { ReactElement } from 'react';

import { Nav, PageHead } from '@/components';
import { About, Contact, Home, Journey, Projects, TechStack } from '@/components/sections';
import Layout from '@/layout/main.layout';

const IndexPage = (): ReactElement => {
  return (
    <Layout>
      <PageHead
        title="Parth Patel | Software Engineer"
        description="A Geeky boy turned into tech wizard over a period of time."
        keywords="Full stack developer, Front-end developer,Developer,Senior Software Engineer"
      />
      <Nav />
      <Home />
      <About />
      <TechStack />
      <Journey />
      <Projects />
      <Contact />
    </Layout>
  );
};

export default IndexPage;
