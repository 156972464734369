import React, { ReactElement } from 'react';
import { FaHubspot } from '@react-icons/all-files/fa/FaHubspot';
import { FaLaptopCode } from '@react-icons/all-files/fa/FaLaptopCode';
import { FaUserSecret } from '@react-icons/all-files/fa/FaUserSecret';
import { IoLogoGameControllerB } from '@react-icons/all-files/io/IoLogoGameControllerB';
import { ABOUT_SECTION_REF, useAnimationHelper } from '@/utils/sections.helper';

import styles from './skill-card.module.scss';

interface ISkillCard {
  iconName: string;
  title: string;
  description: string;
  animationDelay?: '0.2s' | '0.4s' | '0.6s';
}

const getIcon = (name: string) => {
  switch (name) {
    case 'engineer':
      return FaUserSecret;
    case 'tech-enthusiast':
      return FaLaptopCode;
    case 'game':
      return IoLogoGameControllerB;
    case 'path':
      return FaHubspot;
    default:
      return FaUserSecret;
  }
};

const SkillCard = (props: ISkillCard): ReactElement => {
  const { iconName, title, description, animationDelay } = props;
  const shouldAnimate = useAnimationHelper(ABOUT_SECTION_REF, { rootMargin: '0% 0% -60% 0%' });
  const Icon = getIcon(iconName);
  return (
    <div className={`${styles.skill_card} ${shouldAnimate ? styles.animate_in : ''}`}>
      <div className={styles.skill_icon} style={{ animationDelay }}>
        <Icon className={styles.icon} />
      </div>
      <h2 className={styles.skill_title}>{title}</h2>
      <p className={styles.skill_description}>{description}</p>
    </div>
  );
};

export default SkillCard;
