import Resume from '@/assets/resume.pdf';

export const AVAILABLE_COMMANDS = ['about', 'clear', 'commands', 'facts', 'code', 'kill', 'projects', 'resume', 'skills'] as const;

export type IAvailableCommands = typeof AVAILABLE_COMMANDS[number];

export const AVAILABLE_FUN = {
  code: {
    title: `This is what my girlfriend says I look like when I code. Don't know why ! 🤷‍♂️`,
    image: 'https://media.giphy.com/media/QNFhOolVeCzPQ2Mx85/giphy.gif',
  },
  kill: {
    title: `Goodbye Cruel world ! 💀`,
    image: 'https://media.giphy.com/media/Wi8O8WBtYX7dS/giphy.gif',
  },
  skills: {
    title: `My development skills are just as good as Shaun with the basketball: 🏀 `,
    image: 'https://media.giphy.com/media/b5hr3wcYa7ifaW05Uh/giphy.gif',
  },
};

export const downloadResume = (): void => {
  const link = document.createElement('a');
  link.href = Resume;
  link.setAttribute('download', `Parth's Resume.pdf`);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  document.body.removeChild(link);
};

export const getCurrentTime = (): string => {
  const currentDate = new Date();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  const time = hours >= 12 ? 'PM' : 'AM';
  const actualHours = hours > 12 ? hours % 12 : hours;
  return `${actualHours.toString().padStart(2, '0')} : ${minutes} ${time}`;
};
