import React, { ReactElement, useRef, useState } from 'react';
import FlipMove from 'react-flip-move';
import { Title } from '@/components';
import { EVENTS, GAservice } from '@/utils/analytics.helper';
import { PROJECTS_LIST } from '@/utils/projects.helper';
import { PROJECT_SECTION_REF, SECTION_IDs } from '@/utils/sections.helper';
import ProjectCard from './project-card/project-card.component';
import styles from './projects.module.scss';

const Projects = (): ReactElement => {
  const [projectsList, setProjectsList] = useState(PROJECTS_LIST);
  const activeButton = useRef('all');

  const handleClick = (filterKey: 'all' | 'personal' | 'professional') => {
    activeButton.current = filterKey;
    GAservice.publish({ ...EVENTS.project_actions, action: 'filter projects', label: filterKey });
    if (filterKey === 'all') {
      setProjectsList(PROJECTS_LIST);
    } else {
      setProjectsList(PROJECTS_LIST.filter(project => project.type === filterKey));
    }
  };

  return (
    <section id={SECTION_IDs.project} className={styles.container} ref={PROJECT_SECTION_REF}>
      <div className={styles.wrapper}>
        <Title title={'Projects'} reverse />
        <div className={styles.button_container}>
          <button className={activeButton.current === 'all' ? styles.activeButton : ''} onClick={() => handleClick('all')}>
            All
          </button>
          <button className={activeButton.current === 'personal' ? styles.activeButton : ''} onClick={() => handleClick('personal')}>
            Personal
          </button>
          <button
            className={activeButton.current === 'professional' ? styles.activeButton : ''}
            onClick={() => handleClick('professional')}
          >
            Professional
          </button>
        </div>
        <FlipMove duration={500} easing="ease-out" staggerDurationBy="30" className={styles.projects_container}>
          {projectsList.map(project => (
            <ProjectCard key={project.title} {...project} />
          ))}
        </FlipMove>
      </div>
    </section>
  );
};

export default Projects;
