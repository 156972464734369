import React, { ReactElement } from 'react';
import { Title } from '@/components';
import { CONTACT_SECTION_REF, SECTION_IDs, useAnimationHelper } from '@/utils/sections.helper';
import InputForm from './input-form/input-form.component';
import SocialMedia from './social-media/social-media.component';
import styles from './contact.module.scss';

const Contact = (): ReactElement => {
  const shouldAnimate = useAnimationHelper(CONTACT_SECTION_REF, { rootMargin: '0% 0% -60% 0%' });
  return (
    <section id={SECTION_IDs.contact} className={styles.container} ref={CONTACT_SECTION_REF}>
      <div className={styles.inner_wrapper}>
        <Title title={'Contact'} />
        <p className={`${styles.sub_title} ${shouldAnimate ? styles.animate_in_subtitle : ''}`}>
          Any questions or remark? Just write a message !
        </p>
        <div className={`${styles.contact_methods_wrapper} ${shouldAnimate ? styles.animate_in_wrapper : ''}`}>
          <SocialMedia />
          <InputForm />
        </div>
      </div>
    </section>
  );
};

export default Contact;
